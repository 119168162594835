<template>
  <div class="dashboard-wrapper">
    <div v-if="ADMIN_SIGNIN_RES"></div>
    <div class="table-list">
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import { LOG_TYPE } from "../../../config/API_DATA";
import Logger from "../../../services/logger";
import { COMPONENT_NAME } from "../../../util/constants";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      sysBookingNum: null,
      dayToDaySalesRes: null,
    };
  },
  methods: {
    ...mapActions("sDashBoard", {
      SET_AGENT_FINANCE_RESPONSE: "SET_AGENT_FINANCE_RESPONSE",
    }),

    showAlert(val) {
      this.$emit("showAlert", val);
    },
  },
  computed: {
    ...mapGetters("sSysLogin", {
      ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
    }),
    ...mapGetters("sDashBoard", {
      USER_ID: "GET_USER_ID",
    }),
  },
  async created() {
    try {
      this.$root.$emit("toggle-sidebar-minimize", true);

    } catch (err) {
      let body = Logger.buildRequest(
        COMPONENT_NAME.C_SYS_DASHBOARD,
        "error in created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
};
</script>
<style>
[v-cloak].c-sidebar c-sidebar-dark c-sidebar-show c-sidebar-fixed {
  display: none;
}
</style>
