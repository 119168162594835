<template>
  <div>
    <div v-if="ADMIN_SIGNIN_RES">
      <cSysDashboard></cSysDashboard>
    </div>
    <CElementCover v-if="isLoading" :opacity="0.8" />
  </div>
</template>
<script>
import cSysDashboard from "../../components/dashboard/system/cSysDashboard";
import { mapGetters } from "vuex";
import { LOG_TYPE, FILE_NAME, API_VALUES } from "../../config/API_DATA";
import Logger from "../../services/logger";
import { addClassToBody, removeClassBody } from "../../util/util.js";
import { COMPONENT_NAME } from "../../util/constants";
export default {
  name: "Dashboard",
  components: {
    cSysDashboard,
  },
  data() {
    return {
      isLoading: true,
      search: null,
      isShowState: true,

      dayToDaySalesRes: null,
      isShowBookingDetail: false,
      agentBookingDetail: {
        ticketedBookings: null,
        pendingBookings: null,
      },
      userLogoDet: {
        b2bCompanyFinancess: [],
      },
    };
  },
  computed: {
    ...mapGetters("sSysLogin", {
      ADMIN_SIGNIN_RES: "GET_ADMIN_SIGNIN_RES",
      GET_ORGID: "GET_ORGID",
    }),
    // ...mapGetters("sAgtLogin", { GET_SIGNIN_RES: "GET_SIGNIN_RES" }),
    ...mapGetters("sDashBoard", {
      USER_ID: "GET_USER_ID",
    }),
  },

  beforeCreate() {
    addClassToBody(COMPONENT_NAME.DASHBOARD);
  },

  beforeDestroy() {
    removeClassBody();
  },
  async created() {
    // if (BaseConfiguration.isDebug) console.log("created");
    try {
      let orgid = this.GET_ORGID;
      if (this.ADMIN_SIGNIN_RES && orgid) {
        API_VALUES.ORG_ID = orgid;
      }
      this.$root.$emit("toggle-sidebar-minimize", true);
      let that = this;
      setTimeout(function () {
        that.isLoading = false;
      }, 1200);
    } catch (err) {
      let body = Logger.buildRequest(
        FILE_NAME.DASHBOARD,
        "error in created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  },
};
</script>
<style>
[v-cloak].c-sidebar c-sidebar-dark c-sidebar-show c-sidebar-fixed {
  display: none;
}
</style>
